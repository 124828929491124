import { FC } from 'react';
import { Row, Text, TooltipWrapper } from 'lavaa';

type Props = {
    value: string;
    data: any;
};

// Get Color
const getColor = (value: string) => {
    const valueNum = Number(value);

    // Green
    if (valueNum >= 1 && valueNum < 4) {
        return 'var(--success-text-color, #2AA40C)';
    }

    // Orange
    else if (valueNum >= 4 && valueNum <= 6) {
        return 'var(--warning-text-color, #FF9800)';
    }

    // Red
    else if (valueNum >= 7) {
        return 'var(--danger-text-color, #E40931)';
    }
};

// Get tooltip text
const getTooltipText = (value: string) => {
    // 1
    if (value === '1') {
        return '0.0 < HCC < 0.3 and Costs > $500/ 6 mos';
    }

    // 2
    else if (value === '2') {
        return '0.3 < HCC < 0.5 or $500 < Costs < $750/ 6 mos';
    }

    // 3
    else if (value === '3') {
        return '0.5 < HCC < 0.75 or $750 < Costs < $1,000/ 6 mos';
    }

    // 4
    else if (value === '4') {
        return '0.75 < HCC < 1.25 or $1,000 < Costs < $2,500/ 6 mos';
    }

    // 5
    else if (value === '5') {
        return '1.25 < HCC < 1.75 or $2,500 < Costs < $7,500/ 6 mos';
    }

    // 6
    else if (value === '6') {
        return 'HCC > 1.75 or $7,500 < Costs < $10,000/ 6 mos';
    }

    // 7
    else if (value === '7') {
        return 'HCC < 2.0 and Costs > $10,000/ 6 mos or 1.75 < HCC < 2.0 and $7,500 < Costs < $10,000/ 6 mos';
    }

    // 8
    else if (value === '8') {
        return 'HCC > 2.0 and Costs > $10,000/ 6 mos';
    }

    // 9
    else if (value === '9') {
        return 'ER Visit';
    }

    // 10
    else if (value === '10') {
        return 'Hospital Admission/ Discharge';
    }

    return '';
};

// LavaaWorryScoreCellRenderer Component
export const LavaaWorryScoreCellRenderer: FC<Props> = (props: Props) => {
    const { value, data } = props;
    
    return (
        <Row grow="1" shrink="1" justifycontent="center" alignitems="center">
            <TooltipWrapper tooltipText={props?.data?.TooltipMessageWorryScore || ''} positionV="top" positionH="right" whiteSpace="pre">
                <Text size="x3" bold="true" style={{color: getColor(value), textAlign: 'center'}}>
                    {value}
                </Text>
            </TooltipWrapper>
        </Row>
    )
};