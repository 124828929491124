import { FC, useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { noop } from "../../../Tools/Noop";
import { ReportFilterType } from "../Reports.types";
import { useAccount } from "../../../Hooks/UseAccount";
import { Row, Container, Text, Col, Button, DropdownMulti, iconsTypes, Dropdown, Modal, Icon, addSnackbar, useLocalStorage } from "lavaa";
import { useReports } from "../../../Hooks/UseReports";
import { DataTable } from "../../DataTable/DataTable.com";
import { useMixPanel } from "../../../Hooks/UseMixPanel";
import { dateStrComparator } from "../../DataTable/Comparators/Comparators";
import { ReportClientInfo } from "../../Custom/Modals/ReportClientInfo/ReportClientInfo.com";
import { ModalsCtx } from "../../../Context/Modals.context";
import css from "./CCMReports.module.scss";

// CCMReports
const CCMReports: FC = () => {

    // Constants
    const reportName = 'ReportCcmReport';
    const columnFilterName = 'ReportCcmReport_ColumnFilter';
    const filterTypes: any = {
        contains: 0,
        DateRange: 1,
        NumberRange: 2,
        startsWith: 3,
        endsWith: 4,
        equals: 5,
        notEqual: 6,
        notContains: 7,
        blank: 8,
        notBlank: 9
    };

    // public enum FilterType
    // {
    //     TextContain = 0,
    //     DateRange = 1,
    //     NumberRange = 2,
    //     TextStartWith = 3,
    //     TextEndWith = 4,
    //     Equal = 5,
    //     NotEqual = 6,
    //     NotContain = 7,
    //     Blank = 8,
    //     NotBlank = 9
    // }

    // Context
    const { setReportClientInfoId, setReportSourceClientInfoId, setReportClientInfoModalActive } = useContext(ModalsCtx);

    // Hooks
    const [ tableConfig, setTableConfig ] = useLocalStorage<Record<string, any>>(reportName, {});

    const [ columnFilter, setColumnFilter ] = useLocalStorage<Array<string>>(columnFilterName, []);
    const { exportLoading, fetchExcelReportsData, requestReports, getReport, getClientDetailsById, clientDetails, clientDetailsLastUpdate, clearClientDetails } = useReports();
    const { HMSProjectId, account } = useAccount();
    const { reportType = 'ccmReport' } = useParams();
    const reportData = getReport(reportName);
    const { track } = useMixPanel();

    // Refs 
    const isTableInited = useRef(false);

    // States
    const [clientDetailsId, setClientDetailsId] = useState<number>(0);
    
    const [reload, setReload] = useState(0);
    const [headersDefault, setHeadersDefault] = useState([]);
    const [currentHeaders, setCurrentHeaders] = useState(columnFilter);
    const [headersLastUpdate, setHeadersLastUpdate] = useState(0);
    const [reset, setReset] = useState(0);
    const [rows, setRows] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedAttributed, setSelectedAttributed] = useState<any>({name: 'Attribution: All Patients', value: 'all'});
    const [attributedFilter, setAttributedFilter] = useState<any>([]);
    const [columnModel, setColumnModel] = useState([
        {
            field: 'PatientIdFullName', 
            headerName: 'Patient', 
            pinned: true, 
            width: 250, 
            renderer: [
                'PatientCellRenderer', 
                'ClientDetailsCellRenderer'
            ], 
            // setClientDetailsId: (clientId: number) => {setClientDetailsId(clientId); track('Get Client ID', {report: 'CCM Report'});}, 
            setClientDetailsId: handleOpenClientDetails,
            comparator: noop, /*floatingFilterComponent: PatientFilter*/
        },
        {field: 'CcmCurrentMonth', renderer: 'arraySimple', comparator: noop},
        {field: 'CcmPreviousMonth', renderer: 'arraySimple', comparator: noop},
        {field: 'LastEncounter', comparator: dateStrComparator},
        {field: 'TotalCurrentMonth'}
    ]);

    // Table Filter
    const [filter, setFilter] = useState<ReportFilterType>({
        page: 1,
        pageSize: 10,
        search: [],
        sort: {
            sortedByField: 'TotalCurrentMonth',
            isAscending: false,
            sortingType: 0, // 0 - any; 1 - string; 2 - number; 999 - other;
        },
    });

    // Handle Toggle Column
    const handleToggleColumn = (data: any) => {
        const indexedData = data.map((item: any, index: number) => ({...item, checked: true})).sort((a: any, b: any) => a.index - b.index);
        
        setCurrentHeaders(indexedData.filter((header: any) => header.checked));
        setHeadersLastUpdate(performance.now());
        setColumnFilter(indexedData);
    };

    // Handle Reset Columns
    const handleResetColumns = () => {
        const indexedData: any = headersDefault.map((item: any, index: number) => ({...item})).sort((a: any, b: any) => a.index - b.index);
        
        // Reset Column Filter
        setCurrentHeaders(indexedData.filter((header: any) => header.checked));
        setReset(performance.now());

        // Reset Attributed
        handleAttributedSelect({name: 'Attribution: All Patients', value: 'all'});
    };

    // Handle Download Excel Report
    const handleDownloadExcelRepost = () => {
        if (exportLoading == false) {
            const requestFilter: any[] = [];//{search: filter.search, sort: filter.sort};
            fetchExcelReportsData(reportType, reportName, HMSProjectId, account.access_token, [...filter.search, ...attributedFilter]);
        }
    };

    // Handle Attributed Select
    const handleAttributedSelect = (data: any) => {
        const value = data.value; // all | true | false
        let attributedFilterUpdate: any[] = [];

        // Attributed
        if (value === true) {
            attributedFilterUpdate = [
                {filteredByField: 'IsAttributedPatient', filterType: filterTypes.equals, filterTerm: 'true', filterFrom: null, filterTo: null}
            ];
        }

        // Non Attributed
        if (value === false) {
            attributedFilterUpdate = [
                {filteredByField: 'IsAttributedPatient', filterType: filterTypes.equals, filterTerm: 'false', filterFrom: null, filterTo: null}
            ];
        }
        
        // Update States
        setAttributedFilter(attributedFilterUpdate);
        setSelectedAttributed(data);
    };

    // Init Table
    const initTable = (headers: any[]) => {
        console.log('DATA:', headers);
        
        // Prepare Headers
        const indexedHeaders: any = headers
        .filter((row: any) => row.isShownInAppReport)
        .map((header: any, index: number) => {
            return {
                field: header.columnName, 
                headerName: header.displayName, 
                index: header.index,
                checked: header.isShownByDefault
            };
        })
        .sort((a: any, b: any) => a.index - b.index);

        // Prepare Column Model
        const updatedColumnModel = indexedHeaders.map((header: any) => {
            const { field, headerName } = header;
            const colModel = columnModel.find((col: any) => col.field === field);
            return colModel ? {...colModel, headerTooltip: headerName} : {field, headerTooltip: headerName};
        });

        setColumnModel(updatedColumnModel);
        setHeadersDefault(indexedHeaders);
        
        // Save headers to localstore
        if (currentHeaders.length === 0 && indexedHeaders.length > 0) {
            setCurrentHeaders(indexedHeaders.filter((header: any) => header.checked));
            setReset(performance.now());
        }
    };

    // Handle Table Data Changed
    const handleTableDataChanged = (data: any = {}) => {
        const { headers = [], rows = [], totalPages = 1  } = data;

        // Init 
        if (isTableInited.current === false) {
            initTable(headers);
            isTableInited.current = true;
        }

        // Update
        setRows(rows);
        setTotalPages(totalPages);
    };

    // Handle Action
    const handleAction = (actionType: string, data: any) => {
        let updated = false;
        let isFilterChanged = false;
        let updatedFilter = {...filter};

        // Pagination Changed
        if (actionType === 'paginationChanged') {
            const { pageNumber, pageSize } = data;
            updated = true;
            updatedFilter = {...updatedFilter, page: pageNumber, pageSize: pageSize};
        }

        // Filter Changed
        if (actionType === 'filterChanged') {
            const fields: string[] = [];
            const search: any[] = Object.keys(data).map((key: string) => {
                const field = data[key];
                fields.push(field);

                return {
                    filteredByField: key,
                    filterType: filterTypes[field.type],
                    filterTerm: field.filter,
                    filterFrom: null,
                    filterTo: null
                };
            });
            
            updated = true;
            isFilterChanged = true;
            updatedFilter = {...updatedFilter, search};

            // Track Filtering
            track('Filtering', {report: reportName, action: 'filtering', columns: Object.keys(data).join(', ')});
        }
        
        // Sort Changed
        if (actionType === 'sortChanged') {
            updated = true;
            updatedFilter = {
                ...updatedFilter, 
                sort: {
                    ...filter.sort,
                    sortedByField: data ? data.colId : '',
                    isAscending: data ? data.sort === 'asc' : true
                }
            };

            // Track Sorting
            data && track('Sorting', {report: reportName, action: 'sorting', columnName: data.colId});
        }

        // Update Filter
        if (updated) {
            if (isFilterChanged == true) {
                updatedFilter = {...updatedFilter, page: 1};
            }

            setFilter({...updatedFilter});
        }
    };

    // Handle Client Details Modal Close
    const handleClientDetailsModalClose = () => {
        clearClientDetails();
        setClientDetailsId(0);
        // setClientDetailsModalActive(false);
    };

    // Handle Open Client Details
    function handleOpenClientDetails (clientId: number, sourceClientId: string) {
        console.log('HANDLE', clientId, sourceClientId);
        setReportClientInfoId(clientId);
        setReportSourceClientInfoId(sourceClientId);
        setReportClientInfoModalActive(true);
        
        track('Get Client ID', {report: 'CCM Report'});
    };

    // Watch HMSProjectId
    useEffect(() => {
        requestReports(HMSProjectId, reportName, filter.sort, [...filter.search, ...attributedFilter], filter.page, filter.pageSize);
    }, [HMSProjectId, reportName, filter, attributedFilter]);

    // Watch Report Data
    useEffect(() => {
        if (reportData?.lastUpdated) {
            handleTableDataChanged(reportData);
        }
    }, [reportData?.lastUpdated]);
    
    // Watch HMSProjectId & clientDetailsId
    useEffect(() => {
        if (clientDetailsId && HMSProjectId) {
            // Open Modal
            // setClientDetailsModalActive(true);

            // Get Client Details
            getClientDetailsById(clientDetailsId, HMSProjectId);
        }
    }, [HMSProjectId, clientDetailsId]);
    
    return (
        <Container paddingLeft="1rem" paddingRight="1rem" paddingTop="1.5rem" paddingBottom="1.5rem" grow="1" className={css.CCMReports}>
            
            {/* Page Header */}
            <Row alignitems="center" paddingBottom="1rem">

                {/* Page Title */}
                <Row grow="1" paddingRight="2rem">
                    <Text text="Chronic Care Management" size="x5" bold={true} className={css.Title} ellipsis={true}/>
                </Row>

                {/* Column Filter */}
                <Row grow="0" paddingRight="2rem" alignitems="center">

                    {/* Attributed Patient */}
                    <Row paddingLeft="1rem">
                        <Dropdown
                            data={[{name: 'Attribution: All Patients', value: 'all'}, {name: 'Attributed Patients', value: true}, {name: 'Non Attributed Patients', value: false}]}
                            displayField="name" 
                            selectField="value" 
                            selected={selectedAttributed}
                            onSelect={handleAttributedSelect}
                        />
                    </Row>

                    {/* Columns Filter Selector */}
                    <Row paddingLeft="1rem">
                        <DropdownMulti 
                            data={headersDefault}
                            displayField="headerName" 
                            selectField="field" 
                            placeholder="Shown Columns" 
                            initialSelected={currentHeaders} 
                            reset={reset}
                            onSelect={handleToggleColumn}
                        />
                    </Row>

                    {/* Reset Columns */}
                    <Row paddingLeft="1rem">
                        <Button text="Reset" variant="text" type="secondary" icon={iconsTypes.reset} onClick={handleResetColumns} />
                    </Row>
                </Row>

                {/* Export to Excel */}
                <Row>
                    <Button isLoading={exportLoading} size="default" onClick={handleDownloadExcelRepost}>Export to Excel</Button>
                </Row>
            </Row>

            {/* Table */}
            <Col grow="1">
                <DataTable 
                    tableId={reportName}
                    headers={currentHeaders} 
                    rows={rows} 
                    columnModel={columnModel} 
                    minColumnWidth={150} 
                    onAction={handleAction} 
                    totalPages={totalPages} 
                    useServer={true}
                    reload={reload}
                    filteredHeadersLastUpdate={headersLastUpdate}
                />
            </Col>

            {/* Client Details Modal */}
            <ReportClientInfo/>
            
        </Container>
    )
};

export {CCMReports};
