import { FC } from 'react';
import { Row, Col, Text, Icon, iconsTypes } from 'lavaa';
import css from './BoolCellRenderer.module.scss';

type Props = {
    value?: 'true' | 'false' | boolean | 'Yes' | 'No' | 'yes' | 'no';
    colDef?: any;
    data?: any;
    eGridCell: any;
    useXFalse?: boolean;
    useInfNull?: boolean;
};

// BoolCellRenderer Component
export const BoolCellRenderer: FC<Props> = (props: Props) => {
    const { value, colDef = {}, data = {}, eGridCell, useXFalse = false, useInfNull = false } = props;
    const boolValue = (value === 'true' || value === true || value === 'Yes' || value === 'yes') ? true : false;
    const { rowTooltip = '' } = colDef;
    let tooltip = '';
    
    const re = /\$\{(.*?)\}/;
    const match = rowTooltip.match(re);
    
    if (match && match[1]) {
        const fieldName = match[1];

        if (data.hasOwnProperty(fieldName)) {
            tooltip = data[fieldName];
        }
    }

    // Handle Mouse Over
    const handleMouseOver = (e: any) => {
        if (!tooltip) return;
        const wrapperEl = eGridCell.querySelector('.ag-cell-wrapper');
        const valueEl = eGridCell.querySelector('.ag-cell-value');
        console.log('wrapperEl', tooltip);
        
        wrapperEl.style.overflow = 'visible';
        valueEl.style.overflow = 'visible';
    };

    // Handle Mouse Leave
    const handleMouseLeave = (e: any) => {
        if (!tooltip) return;
        const wrapperEl = eGridCell.querySelector('.ag-cell-wrapper');
        const valueEl = eGridCell.querySelector('.ag-cell-value');
        
        wrapperEl.style.overflow = 'hidden';
        valueEl.style.overflow = 'hidden';
    };
    
    return (
        <Row shrink="1" justifycontent="center" alignitems="center" grow="1">
            <Text size="x3" style={{color: boolValue ? 'green' : 'red'}}>
                <span className={tooltip ? css.Tooltip : ''} data-tooltip={tooltip} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    {
                        value === null ? (useInfNull === true ? <Icon name={iconsTypes.miniLink} style={{fontSize: 16, color: 'var(--primary-text-color)'}} /> : null) :
                        boolValue ?
                        <span>✓</span> :
                        useXFalse ? 
                        <span>✗</span> :
                        <Icon name={iconsTypes.exclamation} />
                    }
                </span>
            </Text>
        </Row>
    )
};