import React, { FC } from "react";
import { Col, Row, Text } from "lavaa";
import css from "./LabResults.module.scss";
import { classNames } from '../../../../../Tools';
import { useDemo } from "../../../../../Hooks/useDemo";
import { useClients } from "../../../../../Hooks/UseClients";
import {labResultsCheckRisk} from "./utils";

interface IData {
  label: string,
  value: number,
  max: number,
  min: number,
  state: "normal" | "high" | "extreme"
}

interface IProps {
  search?: string;
}

const LabResults: FC<IProps> = React.memo((props) => {
  const { search } = props;

  const { activeClient } = useDemo();
  const { clientScheme } = useClients();

  if(!activeClient?.client?.LabResults || !clientScheme?.LabResults) return null;

  const gender = activeClient.client.Gender;
  const rawData = activeClient.client.LabResults[0];
  
  const data: IData[] = [];
  Object.entries(rawData).forEach(([key, value]) => {
    const scheme = clientScheme.LabResults.subfields[key];
    if(!scheme) return;

    // Filter by search
    if(search && !scheme.label.toLowerCase().includes(search.toLowerCase())) return;

    const val: number = typeof value === "number" ? value : 0;
    const label = scheme.label;
    const max = scheme.fieldSettings.max;
    const min = scheme.fieldSettings.min;
    const state = labResultsCheckRisk(key, val, gender);

    data.push(
      {
        label: label,
        value: val,
        max: max,
        min: min,
        state: state,
      }
    )
  });

  return (
    <Col className={css.List}>
      {
        data.map((item: any, index: number) => {
          const percent = (item.value - item.min) / (item.max - item.min) * 100;
          const progress = Math.min(percent, 100);
          return (
            <Col key={ index } paddingTop="1.5rem">
              <Row paddingBottom="1rem" justifycontent="space-between">
                <Text text={item.label} size="x2" bold="true" className={css.Label}/>
                <Text text={item.value.toString()} size="x2"/>
              </Row>
              <div className={classNames(css.Progress, item.state === "high" && css.Warning, item.state === "extreme" && css.Danger)} data-progress={progress}>
                <div className={css.ProgressBar} style={{width: `${progress}%`}}/>
              </div>
            </Col>
          )
        })
      }
    </Col>
  )
});

export { LabResults };
