import { FC, useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { noop } from "../../../Tools/Noop";
import { useAccount } from "../../../Hooks/UseAccount";
import { Row, Container, Text, Col, Button, DropdownMulti, iconsTypes, Dropdown, Modal, Icon, addSnackbar, useLocalStorage } from "lavaa";
import { useReports } from "../../../Hooks/UseReports";
import { DataTable } from "../../DataTable/DataTable.com";
import { PatientCellRenderer } from "../../DataTable/CellRenderens/PatientCellRenderer/PatientCellRenderer.com";
import { PatientFilter } from "../../DataTable/Filters/PatientFilter/PatientFilter.com";
import { ReportFilterType } from "../../Reports/Reports.types";
import { formatTimestamp } from "../../../Tools/date";
import { useMixPanel } from "../../../Hooks/UseMixPanel";
import { dateStrComparator, strAsNumComparator } from "../../DataTable/Comparators/Comparators";
import { ReportClientInfo } from "../../Custom/Modals/ReportClientInfo/ReportClientInfo.com";
import { ModalsCtx } from "../../../Context/Modals.context";
import css from "./GeneralReports.module.scss";

// Extend Reports Config
const extendReportConfig: any[] = [
    // {
    //     name: 'ReportGeneralReport',
    //     actions: ['snooze'],
    //     headers: [
    //         {
    //             columnName: 'StartDateForSnooze',
    //             dataType: 'text',
    //             displayName: 'Snooze Patient',
    //             isShownInAppReport: true,
    //             isShownInFileReport: false
    //         }
    //     ],
    //     columnModel: {
    //         field: 'StartDateForSnooze', 
    //         headerTooltip: 'Snooze Patient', 
    //         filter: false, 
    //         renderer: 'button', 
    //         action: 'snoozePatientNew', 
    //         rendererOptions: {
    //             text: 'Snooze Patient', 
    //             type: 'secondary'
    //         }, 
    //         cellStyle: {
    //             border: 'none'
    //         },
    //         pinned: true
    //     }
    // }
];

// GeneralReports
const GeneralReports: FC = () => {

    // Constants
    const reportName = 'ReportGeneralReport';
    const columnFilterName = 'ReportGeneralReport_ColumnFilter';
    const filterTypes: any = {
        contains: 0,
        dateRange: 1,
        numberRange: 2,
        startsWith: 3,
        endsWith: 4,
        equals: 5,
        notEqual: 6,
        notContains: 7,
        blank: 8,
        notBlank: 9,
        lessThan: 10,
        greaterThan: 11,
        lessThanOrEqual: 12,
        greaterThanOrEqual: 13,
        lessThanOrNull: 14,
        greaterThanOrNull: 15
    };

    // public enum FilterType
    // {
    //     TextContain = 0,
    //     DateRange = 1,
    //     NumberRange = 2,
    //     TextStartWith = 3,
    //     TextEndWith = 4,
    //     Equal = 5,
    //     NotEqual = 6,
    //     NotContain = 7,
    //     Blank = 8,
    //     NotBlank = 9
    // }

    // Context
    const { setReportClientInfoId, setReportSourceClientInfoId, setReportClientInfoModalActive } = useContext(ModalsCtx);

    // Hooks
    const [ tableConfig, setTableConfig ] = useLocalStorage<Record<string, any>>(reportName, {});
    
    const [ columnFilter, setColumnFilter ] = useLocalStorage<Array<string>>(columnFilterName, []);
    const { exportLoading, fetchExcelReportsData, requestReports, getReport, runReportAction, snoozeUpdated, getClientDetailsById, clientDetails, clientDetailsLastUpdate, clearClientDetails } = useReports();
    const { HMSProjectId, account } = useAccount();
    const { reportType = 'generalReport' } = useParams();
    const reportData = getReport(reportName);
    const { track } = useMixPanel();

    // Refs 
    const isTableInited = useRef(false);

    // States new
    const [rows, setRows] = useState<any[]>([]);
    
    // States
    const [clientDetailsId, setClientDetailsId] = useState<number>(0);

    const [reload, setReload] = useState(0);
    const [headersDefault, setHeadersDefault] = useState([]);
    const [currentHeaders, setCurrentHeaders] = useState(columnFilter);
    const [headersLastUpdate, setHeadersLastUpdate] = useState(0);
    const [reset, setReset] = useState(0);
    
    const [totalPages, setTotalPages] = useState(100);
    const [snoozeSearch, setSnoozeSearch] = useState<any>([]);
    const [selectedSnooze, setSelectedSnooze] = useState({name: 'Snooze: All Patients', value: 'all'});
    const [selectedAttributed, setSelectedAttributed] = useState<any>({name: 'Attribution: All Patients', value: 'all'});
    const [attributedFilter, setAttributedFilter] = useState<any>([]);
    const [columnModel, setColumnModel] = useState([
        {
            field: 'PatientIdFullName', 
            headerName: 'Patient', 
            headerTooltip: 'Patient', 
            pinned: true, 
            width: 300, 
            renderer: [
                'PatientCellRenderer', {
                    name: 'snooze', options: {useHandleAction: true}
                }, 
                'ClientDetailsCellRenderer'
            ], 
            snoozePatient: true, 
            setClientDetailsId: handleOpenClientDetails,
            comparator: noop
        },
        {field: 'LavaaWorryScore', renderer: ['LavaaWorryScore'], comparator: noop, pinned: true},
        {field: 'LastLdl', comparator: strAsNumComparator},
        {field: 'LastGfr', comparator: strAsNumComparator},
        {field: 'LastA1c', comparator: strAsNumComparator},
        {field: 'Bmi', comparator: strAsNumComparator},
        {field: 'NumHospitalizationLastSixMonth', comparator: strAsNumComparator},
        {field: 'Overdue', comparator: strAsNumComparator},
        {field: 'ManualWorryScore', comparator: strAsNumComparator},
        {field: 'LastAppointment', comparator: dateStrComparator},
        {field: 'LastEncounter', comparator: dateStrComparator},
        {field: 'NextRequiredAppointment', comparator: dateStrComparator, sortable: false},
        {field: 'NextScheduledAppointment', comparator: dateStrComparator},
        {field: 'LastAWV', comparator: dateStrComparator},
        {field: 'NextAWV', comparator: dateStrComparator},
        {field: 'LastHospitalization', comparator: dateStrComparator}
    ]);
    
    // Table Filter
    const [filter, setFilter] = useState<ReportFilterType>({
        page: 1,
        pageSize: 10,
        search: [],
        sort: {
            sortedByField: 'LavaaWorryScore',
            isAscending: false,
            sortingType: 0, // 0 - any; 1 - string; 2 - number; 999 - other;
        },
    });

    // Handle Toggle Column
    const handleToggleColumn = (data: any) => {
        console.log('Handle Toggle Column', data);
        
        const indexedData = data.map((item: any, index: number) => ({...item, checked: true})).sort((a: any, b: any) => a.index - b.index);
        
        setCurrentHeaders(indexedData.filter((header: any) => header.checked));
        setHeadersLastUpdate(performance.now());
        setColumnFilter(indexedData);
    };

    // Handle Reset Columns
    const handleResetColumns = () => {
        const indexedData: any = headersDefault.map((item: any, index: number) => ({...item})).sort((a: any, b: any) => a.index - b.index);
        
        // Reset Column Filter
        setCurrentHeaders(indexedData.filter((header: any) => header.checked));
        setReset(performance.now());

        // Reset Snooze
        handleSnoozeSelect({name: 'All Patients', value: 'all'});

        // Reset Attributed
        handleAttributedSelect({name: 'Attribution: All Patients', value: 'all'});
    };

    // Handle Download Excel Report
    const handleDownloadExcelRepost = () => {
        if (exportLoading == false) {
            fetchExcelReportsData(reportType, reportName, HMSProjectId, account.access_token, [...filter.search, ...snoozeSearch, ...attributedFilter]);
        }
    };

    // Handle Attributed Select
    const handleAttributedSelect = (data: any) => {
        const value = data.value; // all | true | false
        let attributedFilterUpdate: any[] = [];

        // Attributed
        if (value === true) {
            attributedFilterUpdate = [
                {filteredByField: 'IsAttributedPatient', filterType: filterTypes.equals, filterTerm: 'true', filterFrom: null, filterTo: null}
            ];
        }

        // Non Attributed
        if (value === false) {
            attributedFilterUpdate = [
                {filteredByField: 'IsAttributedPatient', filterType: filterTypes.equals, filterTerm: 'false', filterFrom: null, filterTo: null}
            ];
        }
        
        // Update States
        setAttributedFilter(attributedFilterUpdate);
        setSelectedAttributed(data);
    };

    // Handle Snooze Select
    const handleSnoozeSelect = (data: any) => {
        const value = data.value; // all | snooze | unsnooze
        let snoozeSearchUpdate: any[] = [];

        // Snooze
        if (value === 'snooze') {
            snoozeSearchUpdate = [
                {filteredByField: 'StartDateForSnooze', filterType: filterTypes.lessThan, filterTerm: null, filterFrom: formatTimestamp(+new Date()), filterTo: null},
                {filteredByField: 'FinishDateForSnooze', filterType: filterTypes.greaterThan, filterTerm: null, filterFrom: formatTimestamp(+new Date()), filterTo: null}
            ];
        }

        // UnSnooze
        if (value === 'unsnooze') {
            snoozeSearchUpdate = [
                {filteredByField: 'StartDateForSnooze', filterType: filterTypes.greaterThanOrNull, filterTerm: null, filterFrom: formatTimestamp(+new Date()), filterTo: null},
                {filteredByField: 'FinishDateForSnooze', filterType: filterTypes.lessThanOrNull, filterTerm: null, filterFrom: formatTimestamp(+new Date()), filterTo: null, operator: 1}
            ];
        }

        setSnoozeSearch(snoozeSearchUpdate);
        setSelectedSnooze(data);
        // handleAction('filterChanged', {...filter});
        // setReload(performance.now());
        // requestReports(HMSProjectId, reportName, filter.sort, filter.search, filter.page, filter.pageSize);
        // fetchReportsData(reportType, filter, HMSProjectId, {filterSnooze: value}).then(handleTableDataChanged);
    };

    // Init Table
    const initTable = (headers: any[]) => {
        // Prepare Headers
        const indexedHeaders: any = headers
        .filter((row: any) => row.isShownInAppReport)
        .map((header: any, index: number) => {
            return {
                field: header.columnName, 
                headerName: header.displayName, 
                index: header.index,
                checked: header.isShownByDefault
            };
        })
        .sort((a: any, b: any) => a.index - b.index);
        console.log('Head 11', indexedHeaders);

        // Extend headers with extendReportConfig data
        const reportConfigHeaders = extendReportConfig.find((config: any) => config.reportName === reportName)?.headers;

        if (reportConfigHeaders && headers.length > 0) {
            reportConfigHeaders.forEach((configHeader: any) => {
                const { columnName, displayName } = configHeader;
                const found = indexedHeaders.find((header: any) => header.field === columnName);
                if (!found) {
                    indexedHeaders.push({field: columnName, headerName: displayName, index: indexedHeaders.length});
                }
            });
        }

        // Prepare Column Model
        const updatedColumnModel = indexedHeaders.map((header: any) => {
            const { field, headerName } = header;
            let colModel: any = columnModel.find((col: any) => col.field === field);
            const reportConfigColumnModel = extendReportConfig.find((config: any) => config.name === reportName)?.columnModel;

            // Mix reportConfig with columnModel
            if (reportConfigColumnModel && reportConfigColumnModel.field === field) {
                colModel = {...colModel, ...reportConfigColumnModel};
            }

            return colModel ? {...colModel, headerTooltip: colModel.headerTooltip ? colModel.headerTooltip : headerName} : {field, headerTooltip: headerName};
        });

        setColumnModel(updatedColumnModel);
        setHeadersDefault(indexedHeaders);
        
        // Save headers to localstore
        if (currentHeaders.length === 0 && indexedHeaders.length > 0) {
            setCurrentHeaders(indexedHeaders.filter((header: any) => header.checked));
            setReset(performance.now());
        }
    };

    // Update Table Data
    const updateTableData = (rows: any[], totalPages: number) => {
        setRows(rows);
        setTotalPages(totalPages);
    };

    // Handle Table Data Changed
    const handleTableDataChanged = (data: any = {}) => {
        const { headers = [], rows = [], totalPages = 1  } = data;
        console.log('DATAx', data);
        
        // Init 
        if (isTableInited.current === false) {
            initTable(headers);
            isTableInited.current = true;
        }

        // Update
        updateTableData(rows, totalPages);
    };

    // Handle Action
    const handleAction = (actionType: string, data: any, tableId?: string) => {
        let updated = false;
        let isFilterChanged = false;
        let updatedFilter = {...filter};

        // Column Order
        if (actionType === 'columnOrder') {
            // console.log('Column Order', data);
            const updateHeaders = currentHeaders.map((item: any) => {
                return {
                    ...item,
                    // index: getColumnIndex(item.field, item.index)
                    index: data[item.field]
                };
            }).sort((a: any, b: any) => a.index - b.index);
            console.log('updateHeaders', updateHeaders);
            

            // Save 
            setCurrentHeaders(updateHeaders);
            setColumnFilter(updateHeaders);
        }

        // Column Visible
        if (actionType === 'columnVisible') {
            // console.log('VIS', filteredHeaders);
        }

        // Pagination Changed
        if (actionType === 'paginationChanged') {
            const { pageNumber, pageSize } = data;
            updated = true;
            updatedFilter = {...updatedFilter, page: pageNumber, pageSize: pageSize};
        }

        // Filter Changed
        if (actionType === 'filterChanged') {
            const fields: string[] = [];
            const search: any[] = Object.keys(data).map((key: string) => {
                const field = data[key];
                fields.push(field);

                return {
                    filteredByField: key,
                    filterType: filterTypes[field.type],
                    filterTerm: field.filter,
                    filterFrom: null,
                    filterTo: null,
                    // operator: 0 // 0 - and; 1 - or;
                };
            });

            updated = true;
            isFilterChanged = true;
            updatedFilter = {...updatedFilter, search};

            // Track Filtering
            track('Filtering', {report: reportName, action: 'filtering', columns: Object.keys(data).join(', ')});
        }
        
        // Sort Changed
        if (actionType === 'sortChanged') {
            updated = true;
            updatedFilter = {
                ...updatedFilter, 
                sort: {
                    ...filter.sort,
                    sortedByField: data ? data.colId : '',
                    isAscending: data ? data.sort === 'asc' : true
                }
            };

            // Track Sorting
            data && track('Sorting', {report: reportName, action: 'sorting', columnName: data.colId});
        }

        // Snooze Patient
        if (actionType === 'snoozePatient' && data) {
            const { clientId, from, to, reason } = data;
            
            const parameters = [
                {name: 'clientid', type: 'number', value: clientId},
                {name: 'start_date_for_snooze', type: 'datetime', value: formatTimestamp(from)},
                {name: 'finish_date_for_snooze', type: 'datetime', value: formatTimestamp(to)},
                {name: 'snooze_reason', type: 'text', value: reason}
            ];

            runReportAction(HMSProjectId, 'sp_report_action_snooze', parameters);
            // snoozePatientRequest('set', snoozePatientId, from, to, reason, HMSProjectId).then(() => {
            //     fetchReportsData(reportType, updatedFilter, HMSProjectId, {filterSnooze: selectedSnooze.value}).then(handleTableDataChanged);
            //     setFilter({...updatedFilter});
            // });
        }

        // UnSnooze Patient
        if (actionType === 'unSnoozePatient' && data) {
            const { clientId } = data;
            
            const parameters = [
                {name: 'clientid', type: 'number', value: clientId},
                {name: 'start_date_for_snooze', type: 'datetime', value: null},
                {name: 'finish_date_for_snooze', type: 'datetime', value: null},
                {name: 'snooze_reason', type: 'text', value: ''}
            ];
            runReportAction(HMSProjectId, 'sp_report_action_snooze', parameters);
            // const { snoozePatientId, from, to, reason } = data;
            // snoozePatientRequest('unset', snoozePatientId, null, null, null, HMSProjectId).then(() => {
            //     fetchReportsData(reportType, updatedFilter, HMSProjectId, {filterSnooze: selectedSnooze.value}).then(handleTableDataChanged);
            //     setFilter({...updatedFilter});
            // });
        }

        // Update Filter
        if (updated) {
            if (isFilterChanged == true) {
                updatedFilter = {...updatedFilter, page: 1};
            }
            
            setFilter({...updatedFilter});
        }
    };

    // Handle Client Details Modal Close
    const handleClientDetailsModalClose = () => {
        clearClientDetails();
        setClientDetailsId(0);
        // setClientDetailsModalActive(false);
    };

    // Handle Open Client Details
    function handleOpenClientDetails (clientId: number, sourceClientId: string) {
        console.log('HANDLE', clientId, sourceClientId);
        setReportClientInfoId(clientId);
        setReportSourceClientInfoId(sourceClientId);
        setReportClientInfoModalActive(true);
        
        track('Get Client ID', {report: 'General Report'});
    };

    // Watch HMSProjectId
    useEffect(() => {
        requestReports(HMSProjectId, reportName, filter.sort, [...filter.search, ...snoozeSearch, ...attributedFilter], filter.page, filter.pageSize);
    }, [HMSProjectId, reportName, filter, snoozeUpdated, snoozeSearch, attributedFilter]);

    // Watch Report Data
    useEffect(() => {
        if (reportData?.lastUpdated) {
            handleTableDataChanged(reportData);
        }
    }, [reportData?.lastUpdated]);

    // Watch HMSProjectId & clientDetailsId
    useEffect(() => {
        if (clientDetailsId && HMSProjectId) {
            // Open Modal
            // setClientDetailsModalActive(true);

            // Get Client Details
            getClientDetailsById(clientDetailsId, HMSProjectId);
        }
    }, [HMSProjectId, clientDetailsId]);
    console.log('current headers', currentHeaders);
    
    return (
        <Container paddingLeft="1rem" paddingRight="1rem" paddingTop="1.5rem" paddingBottom="1.5rem" grow="1" className={css.GeneralReports}>
            
            {/* Page Header */}
            <Row alignitems="center" paddingBottom="1rem">

                {/* Page Title */}
                <Row grow="1" paddingRight="2rem">
                    <Text text="Patient Care Prioritization Overview" size="x5" bold={true} className={css.Title} ellipsis={true}/>
                </Row>

                {/* Column Filter */}
                <Row grow="0" paddingRight="2rem" alignitems="center">

                    {/* Attributed Patient */}
                    <Row paddingLeft="1rem">
                        <Dropdown
                            data={[{name: 'Attribution: All Patients', value: 'all'}, {name: 'Attributed Patients', value: true}, {name: 'Non Attributed Patients', value: false}]}
                            displayField="name" 
                            selectField="value" 
                            selected={selectedAttributed}
                            onSelect={handleAttributedSelect}
                        />
                    </Row>
                    
                    {/* Snooze Patient */}
                    <Row paddingLeft="1rem">
                        <Dropdown
                            data={[{name: 'Snooze: All Patients', value: 'all'}, {name: 'Snoozed Patients', value: 'snooze'}, {name: 'Not Snoozed Patients', value: 'unsnooze'}]}
                            displayField="name" 
                            selectField="value" 
                            selected={selectedSnooze}
                            onSelect={handleSnoozeSelect}
                        />
                    </Row>

                    {/* Columns Filter Selector */}
                    <Row paddingLeft="1rem">
                        <DropdownMulti 
                            data={headersDefault}
                            displayField="headerName" 
                            selectField="field" 
                            placeholder="Shown Columns" 
                            initialSelected={currentHeaders} 
                            reset={reset} 
                            onSelect={handleToggleColumn} 
                            disableUncheckLast={true}
                        />
                    </Row>

                    {/* Reset Columns */}
                    <Row paddingLeft="1rem">
                        <Button text="Reset" variant="text" type="secondary" icon={iconsTypes.reset} onClick={handleResetColumns} />
                    </Row>
                </Row>

                {/* Export to Excel */}
                <Row>
                    <Button isLoading={exportLoading} size="default" onClick={handleDownloadExcelRepost}>Export to Excel</Button>
                </Row>
            </Row>

            {/* Table */}
            <Col grow="1">
                <DataTable 
                    tableId={reportName}
                    headers={currentHeaders} 
                    rows={rows} 
                    columnModel={columnModel} 
                    minColumnWidth={150} 
                    onAction={handleAction} 
                    totalPages={totalPages} 
                    useServer={true}
                    reload={reload}
                    filteredHeadersLastUpdate={headersLastUpdate}
                />
            </Col>

            {/* Client Details Modal */}
            <ReportClientInfo/>
        </Container>
    )
};

export {GeneralReports};
