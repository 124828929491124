import React from "react";
import { PatientCellRenderer } from "./PatientCellRenderer/PatientCellRenderer.com";
import { ClientDetailsCellRenderer } from "./ClientDetailsCellRenderer/ClientDetailsCellRenderer.com";
import { SnoozeRenderer } from "./SnoozeRenderer/SnoozeRenderer.com";
import { SnoozeRendererNew } from "./SnoozeRenderer/SnoozeRendererNew.com";
import { SnoozeRendererConfirmOnly } from "./SnoozeRenderer/SnoozeRendererConfirmOnly.com";
import { BoolCellRenderer } from "./BoolCellRenderer/BoolCellRenderer.com";
import { MeasureCellRenderer } from "./MeasureCellRenderer/MeasureCellRenderer.com";
import { LavaaWorryScoreCellRenderer } from "./LavaaWorryScoreCellRenderer/LavaaWorryScoreCellRenderer.com";
import { MoneyCellRenderer } from "./MoneyCellRenderer/MoneyCellRenderer.com";

const Renderers: Record<string, React.FC<any>> = {
    'PatientCellRenderer': PatientCellRenderer,
    'ClientDetailsCellRenderer': ClientDetailsCellRenderer,
    'snooze': SnoozeRendererNew,
    'SnoozeRendererConfirmOnly': SnoozeRendererConfirmOnly,
    'bool': BoolCellRenderer,
    'boolean': BoolCellRenderer,
    'measure': MeasureCellRenderer,
    'LavaaWorryScore': LavaaWorryScoreCellRenderer,
    'money': MoneyCellRenderer
};

export const getRenderer = (rendererName: string, params: any = {}) => {    
    const Renderer: React.FC<any> = Renderers[rendererName] as React.FC<any>;
    if (Renderers[rendererName]) {
        return React.createElement(Renderer, {...params});
    }
    return null;
};