import {labResultsRanges} from "./data.mock";

export const labResultsCheckRisk = (key: string, value: number, gender: "F" | "M") : "normal" | "high" | "extreme" => {
    let ranges: any = labResultsRanges.filter((item: any) => item.name === key);

    let state: 'normal' | 'high' | 'extreme' = 'normal';

    ranges = ranges.filter((item: any) => {
        if(item.hasOwnProperty("gender")) {
            return item.gender === gender;
        }
        else{
            return true;
        }
    });

    if(ranges.length > 0){
        ranges = ranges[0];
    }
    else{
        return state;
    }

    if(checkRiskOnRanges(value, ranges.high)){
        state = 'high';
    }

    if(checkRiskOnRanges(value, ranges.extreme)){
        state = 'extreme';
    }

    return state;
}

function checkRiskOnRanges(value: number, ranges: any[]) : boolean {
    return ranges.some((range) => checkRiskOnRange(value, range));
}

function checkRiskOnRange(value: number, range: any): boolean {
    if (range.min && range.max) {
        return value >= range.min && value <= range.max;
    }
    if (range.min) {
        return value >= range.min;
    }
    if (range.max) {
        return value <= range.max;
    }
    return false;
}